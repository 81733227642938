<div class="cb-login-content">
    <img
        [src]="'assets/images/Checkbox.svg' | assetspipe"
        alt="Checkbox"
        class="login-log-image"
    />
    <mat-card appearance="outlined">
        <cb-spinner [show]="isLoading" [blockLayout]="true"></cb-spinner>
        <mat-card-title>
            {{ 'RESET-PASSWORD.RESET-PASSWORD' | translate }}
        </mat-card-title>
        <mat-card-content>
            <cb-reset-password
                (sendMail)="sendMail($event)"
                [emailSent]="emailSent"
                [isBrokenToken]="brokenToken"
                [account]="account"
                *ngIf="sendEmail"
            ></cb-reset-password>
            <cb-reset-password-new-password
                (resetMail)="resetMail($event)"
                [user]="user"
                *ngIf="resetPassword"
            ></cb-reset-password-new-password>
            <cb-reset-password-notification
                [message]="message"
                *ngIf="showNotification"
            ></cb-reset-password-notification>
        </mat-card-content>
    </mat-card>
</div>
