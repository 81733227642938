<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <p>
        <strong
            >{{ 'RESET-PASSWORD.CHANGE-FOR' | translate }} {{ user }}
        </strong>
    </p>
    <div class="form-group">
        <mat-form-field class="full-width-input">
            <mat-label>{{ 'RESET-PASSWORD.NEW-PASSWORD' | translate }}</mat-label>
            <input
                matInput
                type="password"
                formControlName="password"
            />
            <mat-error
                *ngIf="
                    form.controls['password'].hasError('required');
                    else validation_error
                "
            >
                {{ 'ADMIN-CONTACTS.PASSWORD.PASSWORD-REQUIRED' | translate }}
            </mat-error>
            <ng-template #validation_error>
                <mat-error>
                    {{ passwordValidationError }}
                </mat-error>
            </ng-template>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="full-width-input">
            <input
                matInput
                type="password"
                formControlName="passwordConfirmation"
            />
            <mat-error>
                {{
                    'ADMIN-CONTACTS.PASSWORD.MISMATCH-CONFIRMATION' | translate
                }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="cb-take-survey-buttons-container">
        <button
            mat-button
            type="button"
            class="btn-cancel"
            [routerLink]="['/login']"
        >
            {{ 'RESET-PASSWORD.BACK' | translate }}
        </button>
        <button
            mat-button
            class="btn-next"
            type="submit"
            [disabled]="!form.valid"
        >
            {{ 'RESET-PASSWORD.RESET-PASSWORD' | translate }}
        </button>
    </div>
</form>
