import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { EMPTY, merge as observableMerge, Observable, of, throwError } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SharedUrls } from '../../../shared/consts/urls';
import { ExternalSignin, Identity, JWTSignInEffectData, Signin, Signup } from '../../models';
import { ChangePasswordPayload } from '../../models/change-password.model';
import { IdentityProvider, JWTProvider } from '../../providers';
import * as actions from '../actions';

@Injectable()
export class IdentityEffect {
    constructor(
        private actions$: Actions,
        private identityProvider: IdentityProvider,
        private permService: NgxPermissionsService,
        private jwtProvider: JWTProvider,
        private router: Router
    ) {
    }

    checkToken$: Observable<Action> =  createEffect(() => { // Todo migration: Test this method
        return this.actions$.pipe(
            ofType(actions.identity.CHECK_TOKEN),
            startWith(new actions.identity.CheckTokenAction()),
            switchMap(() =>
                this.identityProvider.checkToken().pipe(
                    map((i: Identity) => {
                        const rls =
                            typeof i.roles === 'string'
                                ? i.roles.split(',')
                                : i.roles;
                        this.permService.addPermission(rls);
                        return new actions.identity.CheckTokenSuccessAction(i);
                    }),
                    catchError(err =>
                        observableMerge(
                            of(new actions.error.HandleErrorAction(err)),
                            of(new actions.identity.IdentityFailedAction())
                        )
                    )
                )
            )
        )
    });

    signIn$: Observable<Action> = createEffect(() => { // Todo migration: Test this method
        return this.actions$.pipe(
            ofType<actions.identity.SigninAction>(actions.identity.SIGNIN),
            map(action => action.payload),
            switchMap((data: Signin) =>
                this.identityProvider.signIn(data).pipe(
                    map((i: Identity) => {
                        const rls =
                            typeof i.roles === 'string'
                                ? i.roles.split(',')
                                : i.roles;
                        this.permService.addPermission(rls);
                        return new actions.identity.SigninSuccessAction(i);
                    }),
                    catchError(err => {
                        if (err.error.error === 'expired_password') {
                            this.router.navigateByUrl(SharedUrls.changePassword, { state: data });
                            return EMPTY;
                        } else {
                            return throwError(err);
                        }
                    }),
                    catchError(err =>
                        observableMerge(
                            of(new actions.error.HandleErrorAction(err)),
                            of(new actions.identity.IdentityFailedAction())
                        )
                    )
                )
            )
        )
    });

    signUp$: Observable<Action> = createEffect(() => { // Todo migration: Test this methodreturn
        return this.actions$.pipe(
        ofType<actions.identity.SignupAction>(actions.identity.SIGNUP),
        map(action => action.payload),
        switchMap((data: Signup) =>
            this.identityProvider.signUp(data).pipe(
                map((i: Identity) => {
                    this.permService.addPermission(i.roles);
                    return new actions.identity.SignupSuccessAction(i);
                }),
                catchError(err =>
                    observableMerge(
                        of(new actions.error.HandleErrorAction(err)),
                        of(new actions.identity.IdentityFailedAction())
                    )
                )
            )
        )
    )});

    signOut$: Observable<Action> = createEffect(() => { // Todo migration: Test this method
        return this.actions$.pipe(
            ofType(actions.identity.SIGNOUT),
            switchMap(() => {
                    return this.identityProvider.logOut().pipe(
                        map(() => {
                            return new actions.identity.SignoutSuccessAction();
                        }),
                        catchError(err =>
                            observableMerge(
                                of(new actions.error.HandleErrorAction(err)),
                                of(new actions.identity.IdentityFailedAction())
                            )
                        )
                    );
                }
            )
        )
    });

    signOutInvalidToken$: Observable<Action> = createEffect(() => { // Todo migration: Test this method
       return this.actions$.pipe(
            ofType(actions.identity.SIGNOUT_INVALID_TOKEN),
            switchMap(() => {
                    return this.identityProvider.logOut(true).pipe(
                        map(() => {
                            return new actions.identity.SignoutSuccessAction();
                        }),
                        catchError(err =>
                            observableMerge(
                                of(new actions.error.HandleErrorAction(err)),
                                of(new actions.identity.IdentityFailedAction())
                            )
                        )
                    );
                }
            )
        )
    });

    signInWithJWT$: Observable<Action> = createEffect(() => { // Todo migration: Test this method
        return this.actions$.pipe(
            ofType<actions.identity.SignInWithJWTAction>(
                actions.identity.SINGIN_WITH_JWT
            ),
            map(action => action.payload),
            switchMap((data: JWTSignInEffectData) => {
                    return this.jwtProvider.initAppWithJWT(data).pipe(
                        map(([identity]) => {
                            return new actions.identity.SigninSuccessAction(identity);
                        }),
                        catchError(err => {
                                return observableMerge(
                                    of(new actions.error.HandleErrorAction(err)),
                                    of(new actions.identity.IdentityFailedAction())
                                );
                            }
                        )
                    );
                }
            )
        )
    });

    externalSignIn$: Observable<Action> = createEffect(() => { // Todo migration: Test this method
        return this.actions$.pipe(
            ofType<actions.identity.ExternalSigninAction>(actions.identity.EXTERNAL_SIGNIN),
            map(action => action.payload),
            switchMap((data: ExternalSignin) =>
                this.identityProvider.externalSignIn(data).pipe(
                    map((i: Identity) => {
                        const rls =
                            typeof i.roles === 'string'
                                ? i.roles.split(',')
                                : i.roles;
                        this.permService.addPermission(rls);
                        return new actions.identity.SigninSuccessAction(i);
                    }),
                    catchError(err =>
                        observableMerge(
                            of(new actions.error.HandleErrorAction(err)),
                            of(new actions.identity.IdentityFailedAction())
                        )
                    )
                )
            )
        )
    });

    changePassword$: Observable<Action> = createEffect(() => { // Todo migration: Test this method
        return this.actions$.pipe(
            ofType<actions.identity.ChangePasswordAction>(actions.identity.CHANGE_PASSWORD),
            map(action => action.payload),
            switchMap((data: ChangePasswordPayload) =>
                this.identityProvider.changePassword(data?.signInModel?.id, {
                    old_password: data?.signInModel?.password,
                    new_password: data.newPassword
                }).pipe(
                    map(response => {
                        const signIn = { ...data.signInModel, password: data.newPassword };
                        return new actions.identity.SigninAction(signIn);
                    }),
                    catchError(err =>
                        observableMerge(
                            of(new actions.error.HandleErrorAction(err)),
                            of(new actions.identity.IdentityFailedAction())
                        )
                    )
                )
            )
        )
    });
}
