import { merge as observableMerge, Observable, of } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { error, users } from '../actions';
import { Contact, Contacts, CreateContactModel } from '../../models';
import { UsersProvider } from '../../providers';
import { PageRequestOptions } from '../../../infrastructure/models';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class UsersEffect {
    constructor(
        private actions$: Actions,
        private usersProvider: UsersProvider
    ) {}

    loadUsers$: Observable<Action> = createEffect(() => { // Todo migration: Test this method
        return this.actions$.pipe(
            ofType(users.GET_USERS),
            switchMap(() =>
                this.usersProvider.loadContacts().pipe(
                    map((us: Contacts) => new users.GetUsersSuccessAction(us)),
                    catchError(err =>
                        observableMerge(
                            of(new error.HandleErrorAction(err)),
                            of(new users.UserFailedAction())
                        )
                    )
                )
            )
        )
    });

    loadUsersPage$: Observable<Action> = createEffect(() => { // Todo migration: Test this method
        return this.actions$.pipe(
            ofType<users.GetUsersPageAction>(users.GET_USERS_PAGE),
            map(action => action.payload),
            switchMap((options: PageRequestOptions) =>
                this.usersProvider.loadContactsPage(options, true).pipe(
                    map((us: Contacts) => new users.GetUsersSuccessAction(us)),
                    catchError(err =>
                        observableMerge(
                            of(new error.HandleErrorAction(err)),
                            of(new users.UserFailedAction())
                        )
                    )
                )
            )
        )
    });

    addUser$: Observable<Action> = createEffect(() => { // Todo migration: Test this method
        return this.actions$.pipe(
            ofType<users.AddUserAction>(users.ADD_USER),
            map(action => action.payload),
            switchMap((user: CreateContactModel) =>
                this.usersProvider.addContact(user).pipe(
                    map((us: Contact) => new users.AddUserSuccessAction(us)),
                    catchError(err =>
                        observableMerge(
                            of(new error.HandleErrorAction(err)),
                            of(new users.UserFailedAction())
                        )
                    )
                )
            )
        )
    });

    loadUserById$: Observable<Action> = createEffect(() => { // Todo migration: Test this method
        return this.actions$.pipe(
            ofType<users.GetUserAction>(users.GET_USER),
            map(action => action.payload),
            switchMap((userId: string) =>
                this.usersProvider.loadContact(userId).pipe(
                    map((user: Contact) => new users.GetUserSuccessAction(user)),
                    catchError(err =>
                        observableMerge(
                            of(new error.HandleErrorAction(err)),
                            of(new users.UserFailedAction())
                        )
                    )
                )
            )
        )
    });

    removeUser$: Observable<Action> = createEffect(() => { // Todo migration: Test this method
        return this.actions$.pipe(
            ofType<users.RemoveUserAction>(users.REMOVE_USER),
            map(action => action.payload),
            switchMap((userId: string) =>
                this.usersProvider.removeContact(userId).pipe(
                    map(() => new users.GetUsersAction()),
                    catchError(err =>
                        observableMerge(
                            of(new error.HandleErrorAction(err)),
                            of(new users.UserFailedAction())
                        )
                    )
                )
            )
        )
    });
}
