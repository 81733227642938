<mat-card appearance="outlined">
    <mat-card-title>{{ 'NOT-FOUND.404' | translate }}</mat-card-title>
    <mat-card-content>
        <p>{{ 'NOT-FOUND.404-MESSAGE' | translate }}.</p>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" routerLink="/">
            {{ 'NOT-FOUND.TAKE-ME-HOME' | translate }}
        </button>
    </mat-card-actions>
</mat-card>
