import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { AutoUnsubscribe } from '../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { EmailVerificationProvider } from '../../../../src/app-admin/providers/support/email-verification.provider';
import { ContactEmailVerificationCase, ContactEmailVerificationCodeStatus } from '../../../../src/app-admin/models/support/email-verification.model';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../../../src/infrastructure/services';
import { String } from 'typescript-string-operations-ng4';

@Component({
  selector: 'app-email-verification-dialog',
  templateUrl: './email-verification-dialog.component.html',
  styleUrls: ['./email-verification-dialog.component.scss']
})
@AutoUnsubscribe()
export class EmailVerificationDialogComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    code: string;
    contactId: string;
    email: string;
    password: string;
    verificationCase: ContactEmailVerificationCase;
    descriptionText: string;
    headerText: string;
    private componentDestroyed = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<EmailVerificationDialogComponent>,
        private provider: EmailVerificationProvider,
        private translateService: TranslateService,
        private toasterService: ToasterService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    ngOnInit() {
        this.contactId = this.data.contactId;
        this.verificationCase = this.data.verificationCase;
        this.email = this.data.email ? this.data.email : '';
        this.password = this.data.password ? this.data.password : '';

        this.form = this.fb.group({
            code: [this.code, Validators.required]
        });

        this.sendCode(false, false);
        this.initText(this.data.verificationCase);
    }

    private initText(verificationCase: ContactEmailVerificationCase) {   
        this.translateService
            .get(`EMAIL-VERIFICATION.DIALOG.DESCRIPTION-${verificationCase}`)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((data: string) => {
                if (verificationCase == ContactEmailVerificationCase.MfaLogin) {
                    this.provider.getSettings()
                        .pipe(takeUntil(this.componentDestroyed))
                        .subscribe(res => {
                            this.descriptionText = String.Format(
                                data,
                                res.expiration_period_in_days.toString()
                            );
                        });
                } else {
                    this.descriptionText = data;
                }
            });

        this.translateService
            .get(`EMAIL-VERIFICATION.DIALOG.HEADER-${verificationCase}`)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((data: string) => {
                this.headerText = data;
            });
    }

    resendCode() {
        this.sendCode(true, true);
    }

    private sendCode(forcely: boolean, showNotification: boolean) {
        this.provider.sendVerificationCode(this.contactId, this.verificationCase, forcely, this.email, this.password)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                if (showNotification) {
                    const key = "EMAIL-VERIFICATION.DIALOG.SUCCESSFULLY-SENT";
                    this.translateService.get(key)
                    .pipe(takeUntil(this.componentDestroyed))
                    .subscribe(message =>
                        this.toasterService.showInfo(message, true)
                    );
                }
            });
    }

    onSubmit() {
        const code = this.form.value.code;
        if (this.form.valid) {          
            if (this.verificationCase === ContactEmailVerificationCase.MfaLogin) {
                this.provider.issueToken(this.contactId, code)
                    .pipe(takeUntil(this.componentDestroyed))
                    .subscribe(res => {
                        if (res.code_status == ContactEmailVerificationCodeStatus.Valid)
                            this.dialogRef.close({ status: res.code_status, token: res.token });
                        else
                            this.showValidationError(res.code_status);              
                    });
            } else {
                this.provider.verifyEmailByCode(this.contactId, code, this.password)
                    .pipe(takeUntil(this.componentDestroyed))
                    .subscribe(status => {
                        if (status == ContactEmailVerificationCodeStatus.Valid)
                            this.dialogRef.close({ status: status, code: code });
                        else 
                            this.showValidationError(status);              
                    });
            }
        }
    }

    private showValidationError(status: ContactEmailVerificationCodeStatus) {
        const key = "EMAIL-VERIFICATION.CODE-VALIDATION-RESULT." + status.toUpperCase();
        this.translateService.get(key)
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(message => this.toasterService.showError({message: message}, true));
    }

    disableSubmitButton() {
        return !this.form.value.code || this.form.value.code.trim() === '';
    }

    ngOnDestroy() {}
}
