<div class="cb-signup-content">
    <mat-card appearance="outlined">
        <mat-card-title>
            Sign Up
        </mat-card-title>
        <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
                <mat-form-field class="full-width-input">
                    <mat-label>Contact</mat-label>
                    <input
                        matInput
                        formControlName="id"
                    />
                    <mat-error>
                        Please type your user name
                    </mat-error>
                </mat-form-field>
                <div formGroupName="passwords" class="password-validation">
                    <mat-form-field class="full-width-input">
                        <mat-label>Password</mat-label>
                        <input
                            matInput
                            type="password"
                            formControlName="password"
                        />
                        <mat-error
                            *ngIf="
                                formPasswordsGroup.controls[
                                    'password'
                                ].hasError('required');
                                else validation_error
                            "
                        >
                            {{
                                'ADMIN-CONTACTS.PASSWORD.PASSWORD-REQUIRED'
                                    | translate
                            }}
                        </mat-error>
                        <ng-template #validation_error>
                            <mat-error>
                                {{ passwordValidationError }}
                            </mat-error>
                        </ng-template>
                    </mat-form-field>
                    <mat-form-field class="full-width-input">
                        <mat-label>Confirm password</mat-label>
                        <input
                            matInput
                            type="password"
                            formControlName="confirmPassword"
                        />
                        <mat-error>
                            Password mismatch
                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-card-actions>
                    <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        [disabled]="!form.valid"
                    >
                        Login
                    </button>
                </mat-card-actions>
            </form>
        </mat-card-content>
    </mat-card>
</div>
