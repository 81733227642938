import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ApplicationSettingsProvider } from '../../settings/application-settings/application-settings.provider';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { PreferenceApplicationSettings, AppTimeZone } from '../../settings/application-settings/preferences-application-settings/perferences-application-settings.model';
import { String } from 'typescript-string-operations-ng4';

@Component({
  selector: 'cb-set-time-zone-dialog',
  templateUrl: './set-time-zone-dialog.component.html',
  styleUrls: ['./set-time-zone-dialog.component.scss']
})
export class SetTimeZoneDialogComponent implements OnInit {
    isLoading: boolean;
    preferences: PreferenceApplicationSettings;
    timeZones: AppTimeZone[];
    legacyTimeZoneDescription = '';
    isLegacyTimeZoneSet = false;
    form: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<SetTimeZoneDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private applicationSettingsProvider: ApplicationSettingsProvider,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.preferences = this.data.preferences;

        if (this.preferences.time_zone_id) {
            this.isLegacyTimeZoneSet = false;
        } else {
            this.showLegacyTimeZoneDescription();
        }

        this.form = this.fb.group({
            timeZoneId: [this.preferences.time_zone_id, [Validators.required]]
        });

        this.applicationSettingsProvider.getTimeZones()
            .pipe(first())
            .subscribe((timeZones: AppTimeZone[]) => {
                    this.timeZones = timeZones;
                    this.isLoading = false;
                },
                err => this.errorHandler(err)
            );
    }

    onSubmit() {
        if (!this.form.valid)
            return;

        this.preferences.time_zone_id = this.form.value.timeZoneId;
        this.applicationSettingsProvider
            .saveSystemPreferences(this.preferences)
            .pipe(first())
            .subscribe(
                data => this.dialogRef.close(this.preferences.time_zone_id),
                err => this.errorHandler(err)
            );
    }

    private errorHandler(error) {
        this.isLoading = false;
        this.dialogRef.close(this.preferences.time_zone_id);
    }

    private showLegacyTimeZoneDescription() {
        const tzOffset = this.preferences?.time_zone_utc_offset_in_hours;
        this.translate
            .get('SYSTEM_SETTINGS.SYSTEM.LEGACY-TIME-ZONE-DESCRIPTION')
            .subscribe((text: string) => {
                const legacyTzName = this.applicationSettingsProvider.getLegacyTimeZoneName(tzOffset);
                this.legacyTimeZoneDescription = String.Format(text, legacyTzName);
                this.isLegacyTimeZoneSet = true;
            });
    }
}
