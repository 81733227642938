{
  "name": "checkbox.ui",
  "version": "8.14.0",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "build-spike-take-survey": "bnr build:spike-app-take-survey",
    "build-spike-admin": "bnr build:spike-app-admin",
    "build-spike": "concurrently \"bnr build:spike-app-take-survey\" \"bnr build:spike-app-admin\"",
    "build-local-take-survey": "bnr build:local-app-take-survey",
    "build-local-admin": "bnr build:local-app-admin",
    "build-local": "concurrently \"bnr build:local-app-take-survey\" \"bnr build:local-app-admin\"",
    "local-take-survey": "concurrently \"bnr serve:local-app-take-survey\"",
    "local-admin": "concurrently \"bnr serve:local-app-admin\"",
    "local": "concurrently \"bnr serve:local-app-take-survey\" \"bnr serve:local-app-admin\"",
    "build-prod-take-survey": "bnr build:prod-app-take-survey",
    "build-prod-admin": "bnr build:prod-app-admin",
    "build-prod": "concurrently \"bnr build:prod-app-take-survey\" \"bnr build:prod-app-admin\"",
    "build-prod-canada": "concurrently \"bnr build:prod-app-take-survey-canada\" \"bnr build:prod-app-admin-canada\"",
    "build-prod-europe": "concurrently \"bnr build:prod-app-take-survey-europe\" \"bnr build:prod-app-admin-europe\"",
    "build-prod-au": "concurrently \"bnr build:prod-app-take-survey-au\" \"bnr build:prod-app-admin-au\"",
    "build-prod-trial": "concurrently \"bnr build:prod-app-take-survey-trial\" \"bnr build:prod-app-admin-trial\"",
    "dev-take-survey": "bnr serve:dev-app-take-survey",
    "dev-admin": "bnr serve:dev-app-admin",
    "start": "concurrently \"bnr serve:dev-app-take-survey\" \"bnr serve:dev-app-admin\"",
    "build-dev-take-survey": "bnr build:dev-app-take-survey",
    "build-dev-admin": "bnr build:dev-app-admin",
    "build-dev": "concurrently \"bnr build:dev-app-take-survey\" \"bnr build:dev-app-admin\"",
    "build-feature": "concurrently \"bnr build:feature-app-take-survey\" \"bnr build:feature-app-admin\"",
    "build-staging-take-survey": "bnr build:staging-app-take-survey",
    "build-staging-admin": "bnr build:staging-app-admin",
    "build-staging": "concurrently \"bnr build:staging-app-take-survey\" \"bnr build:staging-app-admin\"",
    "build-feature-take-survey": "bnr build:feature-app-take-survey",
    "build-feature-admin": "bnr build:feature-app-admin",

    "build-server-take-survey": "bnr build:server-app-take-survey",
    "build-server-admin": "bnr build:server-app-admin",
    "build-server": "concurrently \"bnr build:server-app-take-survey\" \"bnr build:server-app-admin\"",
    "serve-server": "concurrently \"bnr serve:server-app-take-survey\" \"bnr serve:server-app-admin\"",
    "test": "ng test",
    "test-admin": "ng test --app app-admin",
    "test-survey": "ng test --app app-take-survey",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "format": "prettier --write \"src/**/**/*\"",
    "format-scss": "prettier --write \"src/**/**/*.scss\"",
    "format-ts": "prettier --write \"src/**/**/*.ts\"",
    "format-html": "prettier --write \"src/**/**/*.html\"",
    "analyze-prod-admin": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-admin --configuration staging --stats-json && webpack-bundle-analyzer dist/app-admin/stats.json --port 9999",
    "analyze-prod-take-survey": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-take-survey --configuration staging --stats-json && webpack-bundle-analyzer dist/app-take-survey/stats.json",
    "clean": "npm cache clean --force"
  },
  "betterScripts": {
    "build:local-app-take-survey": {
      "command": "ng build --project app-take-survey --configuration local"
    },
    "build:local-app-admin": {
      "command": "ng build --project app-admin --configuration local"
    },
    "serve:local-app-take-survey": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng serve --project app-take-survey --port 4001 --configuration local"
    },
    "serve:local-app-admin": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng serve --project app-admin --port 4002 --configuration local "
    },
    "build:dev-app-take-survey": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-take-survey --configuration dev  --outputHashing all --deploy-url https://cdn.checkbox.com/dev8/survey/"
    },
    "build:dev-app-admin": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-admin --configuration dev  --outputHashing all --deploy-url https://cdn.checkbox.com/dev8/admin/"
    },
    "build:spike-app-take-survey": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-take-survey --configuration spike  --outputHashing all"
    },
    "build:spike-app-admin": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-admin --configuration spike  --outputHashing all"
    },
    "build:staging-app-take-survey": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-take-survey --configuration staging  --outputHashing all --extract-css --deploy-url https://cdn.checkbox.com/staging8/survey/"
    },
    "build:staging-app-admin": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-admin --configuration staging  --outputHashing all --extract-css --deploy-url https://cdn.checkbox.com/staging8/admin/"
    },
    "build:feature-app-take-survey": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-take-survey --configuration feature"
    },
    "build:feature-app-admin": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-admin --configuration feature"
    },
    "serve:dev-app-take-survey": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng serve --project app-take-survey --configuration dev --port 4001 "
    },
    "serve:dev-app-admin": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng serve --project app-admin --configuration dev --port 4002 "
    },
    "build:prod-app-take-survey": {
      "_comment": "Please, be aware that changing --deploy-url below will crash the build process. If you need to edit --deploy-url, change window.__env.cloudFrontTakeSurvey in Checkbox-Application/QA/Environments/online-prod/UI/env.js",
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-take-survey --configuration production --prod --deploy-url https://cdn.checkbox.com/us/survey/"
    },
    "build:prod-app-admin": {
      "_comment": "Please, be aware that changing --deploy-url below will crash the build process. If you need to edit --deploy-url, change window.__env.cloudFrontAdmin in Checkbox-Application/QA/Environments/online-prod/UI/env.js",
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-admin --configuration production --prod --deploy-url https://cdn.checkbox.com/us/admin/"
    },
    "build:prod-app-take-survey-canada": {
      "_comment": "Please, be aware that changing --deploy-url below will crash the build process. If you need to edit --deploy-url, change window.__env.cloudFrontTakeSurvey in Checkbox-Application/QA/Environments/online-prod/UI/env.js",
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-take-survey --configuration production --prod --deploy-url https://cdn.checkbox.com/canada/survey/"
    },
    "build:prod-app-admin-canada": {
      "_comment": "Please, be aware that changing --deploy-url below will crash the build process. If you need to edit --deploy-url, change window.__env.cloudFrontAdmin in Checkbox-Application/QA/Environments/online-prod/UI/env.js",
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-admin --configuration production --prod --deploy-url https://cdn.checkbox.com/canada/admin/"
    },
    "build:prod-app-take-survey-europe": {
      "_comment": "Please, be aware that changing --deploy-url below will crash the build process. If you need to edit --deploy-url, change window.__env.cloudFrontTakeSurvey in Checkbox-Application/QA/Environments/online-prod/UI/env.js",
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-take-survey --configuration production --prod --deploy-url https://cdn.checkbox.com/europe/survey/"
    },
    "build:prod-app-admin-europe": {
      "_comment": "Please, be aware that changing --deploy-url below will crash the build process. If you need to edit --deploy-url, change window.__env.cloudFrontAdmin in Checkbox-Application/QA/Environments/online-prod/UI/env.js",
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-admin --configuration production --prod --deploy-url https://cdn.checkbox.com/europe/admin/"
    },
    "build:prod-app-take-survey-au": {
      "_comment": "Please, be aware that changing --deploy-url below will crash the build process. If you need to edit --deploy-url, change window.__env.cloudFrontTakeSurvey in Checkbox-Application/QA/Environments/online-prod/UI/env.js",
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-take-survey --configuration production --prod --deploy-url https://cdn.checkbox.com/au/survey/"
    },
    "build:prod-app-admin-au": {
      "_comment": "Please, be aware that changing --deploy-url below will crash the build process. If you need to edit --deploy-url, change window.__env.cloudFrontAdmin in Checkbox-Application/QA/Environments/online-prod/UI/env.js",
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-admin --configuration production --prod --deploy-url https://cdn.checkbox.com/au/admin/"
    },
    "build:prod-app-take-survey-trial": {
      "_comment": "Please, be aware that changing --deploy-url below will crash the build process. If you need to edit --deploy-url, change window.__env.cloudFrontTakeSurvey in Checkbox-Application/QA/Environments/online-prod/UI/env.js",
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-take-survey --configuration production --prod --deploy-url https://cdn.checkbox.com/trial/survey/"
    },
    "build:prod-app-admin-rial": {
      "_comment": "Please, be aware that changing --deploy-url below will crash the build process. If you need to edit --deploy-url, change window.__env.cloudFrontAdmin in Checkbox-Application/QA/Environments/online-prod/UI/env.js",
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-admin --configuration production --prod --deploy-url https://cdn.checkbox.com/trial/admin/"
    },
    "build:server-app-take-survey": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-take-survey --configuration server --prod "
    },
    "build:server-app-admin": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --project app-admin --configuration server --prod "
    },
    "serve:server-app-take-survey": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng  serve --project app-take-survey --configuration server --port 4001 "
    },
    "serve:server-app-admin": {
      "command": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng  serve --project app-admin --configuration server --port 4002 "
    }
  },
  "private": true,
  "dependencies": {
    "@almothafar/angular-signature-pad": "^6.0.0",
    "@angular/animations": "18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "18.2.13",
    "@angular/compiler": "18.2.13",
    "@angular/core": "18.2.13",
    "@angular/forms": "18.2.13",
    "@angular/material": "^18.2.14",
    "@angular/material-moment-adapter": "^18.2.14",
    "@angular/platform-browser": "18.2.13",
    "@angular/platform-browser-dynamic": "18.2.13",
    "@angular/router": "18.2.13",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-svg-core": "^6.7.2",
    "@fortawesome/free-brands-svg-icons": "^6.7.2",
    "@fortawesome/free-solid-svg-icons": "^6.7.2",
    "@marker.io/browser": "^0.19.0",
    "@ngrx/effects": "18.1.1",
    "@ngrx/store": "18.1.1",
    "@ngstack/code-editor": "5.1.0",
    "@ngx-translate/core": "15.0.0",
    "@ngx-translate/http-loader": "^6.0.0",
    "@tinymce/tinymce-angular": "5.0.1",
    "@types/c3": "^0.7.4",
    "@types/cross-storage": "^0.8.29",
    "@types/grecaptcha": "^3.0.1",
    "@types/lodash": "4.14.118",
    "angular-tag-cloud-module": "17.0.1",
    "angularx-qrcode": "18.0.2",
    "c3": "^0.7.18",
    "core-js": "^2.6.5",
    "echarts": "^5.4.2",
    "file-saver": "^2.0.2",
    "flagsmith": "9.0.3",
    "html2canvas": "1.0.0-rc.7",
    "intl": "^1.2.5",
    "jspdf": "^2.3.1",
    "lodash": "^4.17.19",
    "moment": "^2.27.0",
    "monaco-editor": "^0.52.2",
    "ng-recaptcha-2": "14.0.0",
    "ngx-clipboard": "15.1.0",
    "ngx-color-picker": "16.0.0",
    "ngx-echarts": "16.2.0",
    "ngx-permissions": "17.1.0",
    "ngx-sharebuttons": "^15.0.6",
    "posthog-js": "1.16.0",
    "reselect": "^4.0.0",
    "rxjs": "7.8.1",
    "tinymce": "^5.8.2",
    "tslib": "^2.0.0",
    "typescript-string-operations": "^1.4.1",
    "typescript-string-operations-ng4": "^0.1.3",
    "xss": "^1.0.7",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.14",
    "@angular-devkit/build-optimizer": "^0.901.9",
    "@angular-devkit/schematics": "^18.2.14",
    "@angular/cli": "18.2.14",
    "@angular/compiler-cli": "18.2.13",
    "@angular/language-service": "18.2.13",
    "@angular/localize": "^18.2.13",
    "@types/file-saver": "^2.0.1",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "~2.0.8",
    "@types/node": "^14.0.14",
    "angular-router-loader": "^0.8.5",
    "better-npm-run": "^0.1.1",
    "codelyzer": "^6.0.0",
    "concurrently": "^5.2.0",
    "jasmine-core": "~3.8.0",
    "jasmine-spec-reporter": "~5.0.0",
    "json-server": "^0.16.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.1.0",
    "karma-cli": "~2.0.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "prettier": "2.0.5",
    "protractor": "~7.0.0",
    "ts-node": "^8.10.2",
    "tslint": "~6.1.0",
    "typescript": "5.4.5",
    "webpack-bundle-analyzer": "^3.8.0"
  }
}
