import {
    Component,
    Output,
    EventEmitter,
    OnInit,
    ChangeDetectionStrategy
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { SyncValidators } from '../../../infrastructure/validators/sync.validators';
import { PasswordService } from '../../../infrastructure/services';

@Component({
    selector: 'cb-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupComponent implements OnInit {
    public form: UntypedFormGroup;
    @Output() signup: EventEmitter<any> = new EventEmitter();

    public get passwordValidationError(): string {
        return this.passwordService.getErrorMessage();
    }

    constructor(
        private fb: UntypedFormBuilder,
        private passwordService: PasswordService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            id: ['', Validators.required],
            passwords: this.fb.group(
                {
                    password: ['', Validators.required],
                    confirmPassword: ['', Validators.required]
                },
                { validator: SyncValidators.Equal }
            )
        });

        this.passwordService.setValidators(
            (this.form.controls['passwords'] as UntypedFormGroup).controls['password']
        );
    }

    onSubmit() {
        if (this.form.valid) {
            this.signup.emit(this.form.value);
        }
    }

    get formPasswordsGroup() {
        return <UntypedFormGroup>this.form.get('passwords');
    }
}
